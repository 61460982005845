import DfStore from "@/vue/domain/store/df-store";
import Utils from "@/vue/infrastructure/utils/helpers";
import {Component, Inject, Vue, Watch} from "vue-property-decorator";
import {CONTAINER_TYPES} from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";

const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfFooter: () => Utils.externalComponent2("df-footer"),
  },
})
export default class DfPageProductComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  beforeMount() {
    const productCode: string = <any>this.$route.query.code || null;
    const promotionCode: string = <any>this.$route.query.promotion || null;

    if (productCode && promotionCode) {
      this.service
        .getProductByExternalData({ promotionCode: promotionCode, storeAlias: this.currentStore.code, productCode: productCode })
        .then((productData: any) => {
          if (productData) {
            this.$router.replace({
              name: "product",
              params: Object.assign({}, {}, { storeAlias: this.currentStore.alias, promotionAlias: productData.area.promotion.alias, productAlias: productData.alias }),
              query: this.$route.query,
            });
          } else {
            this.redirectToPromotions();
          }
        })
        .catch((error: Error) => {
          this.redirectToPromotions();
        });
    } else {
      this.redirectToPromotions();
    }
  }

  private redirectToPromotions() {
    this.$router.push({ name: "promotions", params: { storeAlias: this.currentStore.alias } })
  }
}
